/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import logo from "../img/mesh_logo.png";
import { useState } from 'react';
import { Button, Checkbox, Form, Grid, Input, theme, Typography, message } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import axios from 'axios';
import { apiHost } from "../redux/util/config";
import { setAtAuth, setUsernameAndPassword, setCompanyAndLoginUrl } from "../redux/actions";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;

const Welcome = () => {
  const { token } = useToken();
  const screens = useBreakpoint();
  const dispatch = useDispatch();

  const [formType, setFormType] = useState('login');
  const [cookies, setCookie] = useCookies(['user']);
  const [messageApi, contextHolder] = message.useMessage();

  const queryParameters = new URLSearchParams(window.location.search)
  const companyID = queryParameters.get("id");
  const companyName = queryParameters.get("name");
  const loginUrl = queryParameters.get("loginUrl");

  console.log(`companyId: ${companyID}\n companyName: ${companyName}\n loginUrl: ${loginUrl}`);
  // alert(`companyId: ${companyID}\n companyName: ${companyName}\n loginUrl: ${loginUrl}`);

  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px"
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%"
    },
    forgotPassword: {
      float: "right"
    },
    header: {
      marginBottom: token.marginXL
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px"
    },
    text: {
      color: token.colorTextSecondary
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
    }
  };

  const onFinish = (values) => {
    // console.log("Received values of form: ", values);
    if(!companyID || !companyName || !loginUrl) {
      messageApi.open({
        type: 'error',
        content: 'Error! No Wifi Company to sign to provided!',
      });
    } else {
      dispatch(setCompanyAndLoginUrl({companyId: companyID, companyName, loginUrl}));
      const sendData = {...values, companyID, companyName};
      console.log("sendData: ", sendData);
      axios.post(`${apiHost}/login/user`, sendData, { withCredentials: true })
      .then(res => {
        // console.log("login response: ", res.data);
        if(res.data.user) {
          dispatch(setAtAuth(true));
          dispatch(setUsernameAndPassword(values));
          setCookie('user', res.data.user, { path: '/' })
        }
      }).catch(err => {
        console.log("login error: ", err);
        messageApi.open({
          type: 'error',
          content: err.response.data,
        });
      });
    }
  };

  const onRegister = (values) => {
    // console.log('register values: ', values);
    const data = {...values};
    if(!companyID || !companyName || !loginUrl) {
      messageApi.open({
        type: 'error',
        content: 'Error! No Wifi Company to sign to provided!',
      });
    } else {
      dispatch(setCompanyAndLoginUrl({companyId: companyID, companyName, loginUrl}));
      data.companyId = companyID;
      data.companyName = companyName;
      // console.log("data to send: ", data);
      axios.post(`${apiHost}/register`, data)
      .then(res => {
        // console.log('res: ', res);
        if(res.status === 200) {
          messageApi.open({
            type: 'success',
            content: 'Successfully registered! Now you can login and connect to wifi.',
          });
          setFormType('login');
        }
      }).catch(err => {
        console.log("user register error: ", err.response.data);
        messageApi.open({
          type: 'error',
          content: err.response.data,
        });
      });
    }
  }
  
  const onChangePassword = (values) => {
    // console.log('change password values: ', values);
    axios.post(`${apiHost}/change_password`, values)
    .then(res => {
      // console.log('res: ', res);
      if(res.status === 200) {
        messageApi.open({
          type: 'success',
          content: res.data.message,
        });
        setFormType('login');
      }
    }).catch(err => {
      console.log("user register error: ", err.response.data);
      messageApi.open({
        type: 'error',
        content: err.response.data,
      });
    });
  }

  return (
    <div style={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center" }}>
      {contextHolder}
      <div style={{ display: "flex", alignItems: "center", flexFlow: "column" }}>
          <img alt="logo" style={{ width: "200px", marginBottom: 20 }} src={logo} />
          {
            formType === 'login' ? (
              <Form
                name="normal_login"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                layout="vertical"
                requiredMark="optional"
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      type: "username",
                      required: true,
                      message: "Please input your Username!",
                    },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined />}
                    placeholder="Username"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>
                  <a style={styles.forgotPassword} onClick={() => setFormType('')}>
                    Forgot password?
                  </a>
                </Form.Item>
                <Form.Item style={{ marginBottom: "0px" }}>
                  <Button block="true" type="primary" htmlType="submit">
                    Log in
                  </Button>
                  <div style={styles.footer}>
                    <Text style={styles.text}>Don't have an account?</Text>{" "}
                    <Link onClick={() => setFormType('register')}>Sign up now</Link>
                  </div>
                </Form.Item>
              </Form>
            ) : formType === 'register' ? (
              <Form
                name="register"
                initialValues={{
                  remember: true,
                }}
                onFinish={onRegister}
                layout="vertical"
                requiredMark="optional"
              >
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                  ]}
                >
                  <Input placeholder="Enter your first name:" />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your last name!",
                    },
                  ]}
                >
                  <Input placeholder="Enter your last name:" />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Please input your email address!",
                    },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined />}
                    placeholder="Enter your email address:"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    type="password"
                    placeholder="Enter your password:"
                  />
                </Form.Item>
                <Form.Item
                  name="confirm_password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password again!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Error! The passwords you entered do not match!'));
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    type="password"
                    placeholder="Confirm your password:"
                  />
                </Form.Item>
                <Form.Item style={{ marginBottom: "0px" }}>
                  <Button block="true" type="primary" htmlType="submit">
                    Sign Up
                  </Button>
                  <div style={styles.footer}>
                    <Link onClick={() => setFormType('login')}>Sign In</Link>
                  </div>
                </Form.Item>
              </Form>
            ) : (
              <Form
                name="forgot_password"
                initialValues={{
                  remember: true,
                }}
                onFinish={onChangePassword}
                layout="vertical"
                requiredMark="optional"
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      type: "username",
                      required: true,
                      message: "Please input your Username!",
                    },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined />}
                    placeholder="Username"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your new Password!",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    type="password"
                    placeholder="New Password:"
                  />
                </Form.Item>
                <Form.Item
                  name="confirm_password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your new Password again!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Error! The passwords you entered do not match!'));
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    type="password"
                    placeholder="Confirm your new password:"
                  />
                </Form.Item>
                <Form.Item style={{ marginBottom: "0px" }}>
                  <Button block="true" type="primary" htmlType="submit">
                    Update Password
                  </Button>
                  <div style={styles.footer}>
                    <Link onClick={() => setFormType('login')}>Sign In</Link>
                  </div>
                </Form.Item>
              </Form>
            )
          }
      </div>
    </div>
  )
}

export default Welcome