import {
  SET_ACCOUNT_DATA,
  SET_ACCOUNT_DATA_FETCHING,
  SET_AT_AUTH,
  SET_CONNECT_DATA,
  SET_CONNECT_FETCHING,
  SET_USERNAME_AND_PASSWORD,
  SET_COMPANY_AND_LOGINURL,
} from '../ActionTypes';

const INIT_STATE = {
  item: null,
  isAuth: !!localStorage.getItem("token"),
  isFetching: false,
  isFetchingConnect: false,
  connectData: null,
  username: null,
  password: null,
  companyId: null,
  companyName: null,
  loginUrl: null,
};

const state = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CONNECT_DATA: {
      return {
        ...state,
        connectData: action.payload
      };
    }
    case SET_CONNECT_FETCHING: {
      return {
        ...state,
        isFetchingConnect: action.payload
      };
    }
    case SET_AT_AUTH: {
      return {
        ...state,
        isAuth: action.payload
      };
    }
    case SET_ACCOUNT_DATA: {
      return {
        ...state,
        item: action.payload
      };
    }
    case SET_ACCOUNT_DATA_FETCHING: {
      return {
        ...state,
        isFetching: action.payload,
      };
    }
    case SET_USERNAME_AND_PASSWORD: {
      return {
        ...state,
        username: action.payload.username,
        password: action.payload.password,
      };
    }
    case SET_COMPANY_AND_LOGINURL: {
      return {
        ...state,
        companyId: action.payload.companyId,
        companyName: action.payload.companyName,
        loginUrl: action.payload.loginUrl,
      }
    }
    default:
      return state;
  }
};

export default state;
